<template>
  <div class="widgetContainer transactionsList full-width">
    <div class="widgetContainer__header-fixed">
      <div class="headerExport">
        <p class="header-1">
          {{ $t('transactions') }}
        </p>
        <div v-if="false">
          <span class="icon-icon-export" />
          <p>Export</p>
          <span class="icon-arrow-right" />
        </div>
      </div>
      <div class="transactionsList__search">
        <div class="searchInput">
          <el-input
            type="text"
            v-model="search"
            placeholder="Search Transactions" clearable />
          <span class="icon-icon-search" />
        </div>
        <span class="icon-icon-filter filter-icon" @click="filter" />
      </div>
      <div class="searchTags">
        <div v-if="getFilters.txnType" class="searchTags__tag">
          {{ getFilters.txnType }}
          <span class="icon-icon-close" @click="clear('txnType')" />
        </div>

        <div v-if="getFilters.cardIdFromFilter" class="searchTags__tag">
          {{ getFilters.cardLabelFromFilter }}
          <span class="icon-icon-close" @click="clear('cardIdFromFilter')" />
        </div>
        <div
          v-if="
            (getFilters.minAmount || getFilters.maxAmount) &&
              (getFilters.minAmount != 0.00 || getFilters.maxAmount != 0.00)
          "
          class="searchTags__tag">
          <p
            v-if="
              getFilters.minAmount &&
                getFilters.maxAmount &&
                getFilters.minAmount != 0.00 &&
                getFilters.maxAmount != 0.00
            ">
            {{ getFilters.minAmount | currency }} - {{ getFilters.maxAmount | currency }}
          </p>
          <p v-else-if="getFilters.minAmount && getFilters.minAmount != 0.00">
            {{ getFilters.minAmount | currency }}
          </p>
          <p v-else-if="getFilters.maxAmount && getFilters.maxAmount != 0.00">
            {{ 0.00 | currency }} - {{ getFilters.maxAmount | currency }}
          </p>
          <span class="icon-icon-close" @click="clear('minAmount', '0.00')" />
        </div>
        <div v-if="getFilters.dateRangeValue && (getFilters.dateRangeValue !== 'custom' || ((getFilters.startDate && getFilters.startDate !== '') || (getFilters.endDate && getFilters.endDate !== '')))" class="searchTags__tag">
          <p v-if="getFilters.dateRangeValue === 'this_week'">
            {{ $t('this_week') }}
          </p>
          <p v-else-if="getFilters.dateRangeValue === 'this_month'">
            {{ $t('this_month') }}
          </p>
          <p v-else-if="getFilters.dateRangeValue === 'last_month'">
            {{ $t('last_month') }}
          </p>
          <div v-else-if="getFilters.dateRangeValue === 'custom'">
            <p v-if="getFilters.startDate && getFilters.endDate && getFilters.startDate !== '' && getFilters.endDate !== ''">
              {{ getFilters.startDate }} - {{ getFilters.endDate }}
            </p>
            <p v-else-if="getFilters.startDate && getFilters.startDate !== ''">
              {{ getFilters.startDate }}
            </p>
            <p v-else-if="getFilters.endDate && getFilters.endDate !== ''">
              {{ getFilters.endDate }}
            </p>
          </div>

          <span class="icon-icon-close" @click="clear('dateRangeValue')" />
        </div>
      </div>
    </div>

    <SkeletonList title="This Month" :count="9" v-if="loading && page === 1" />
    <div class="widgetContainer__body transactionsList--dashboard-list" v-else>
      <div class="noData" v-if="getAllTransactions.total === 0">
        <h1 class="header-2">
          Search results
        </h1>
        <p>
          {{
            $t(
              !search || search.length === 0
                ? 'noTransactions'
                : 'noSearchTransactions'
            )
          }}
        </p>
      </div>
      <template v-else>
        <div
          v-infinite-scroll="load"
          infinite-scroll-disabled="disabledLoadMore"
          infinite-scroll-immediate="false">
          <div
            class="transactionsList__list listWithAmountNew"
            v-for="(value, name) in transactions"
            :key="name">
            <h4 class="header-2">
              {{ name }}
            </h4>
            <ul>
              <TransactionComponent
                v-for="t in value"
                :transaction="t"
                @showDetails="showTransactionDetails = true"
                :key="t.id" />
            </ul>
          </div>
          <div class="infinite-loading"
               v-if="loading && page !== 1">
            <p>Loading...</p>
          </div>
        </div>
      </template>
    </div>

    <Drawer class="default-drawer" :components="transactionDetails" :show.sync="showTransactionDetails" v-if="showTransactionDetails" />
    <Drawer class="default-drawer" :components="transactionFilters" :show.sync="showTransactionFilters" v-if="showTransactionFilters" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import TransactionComponent from '../components/Transaction';
import { debounce, formatDate } from '@/utils/general';
import Drawer from '@/components/Drawer.vue';
import SkeletonList from '@/components/Skeleton/SkeletonList.vue';

export default {
  components: {
    TransactionComponent,
    Drawer,
    SkeletonList
  },
  data() {
    return {
      search: null,
      loading: true,
      page: 1,
      limit: 25,
      showTransactionDetails: false,
      showTransactionFilters: false,
      transactionDetails: [
        {
          name: 'transactionDetails',
          default: true,
          component: () => import('@m/transaction/views/TransactionDetails.vue')
        }
      ],
      transactionFilters: [
        {
          name: 'TransactionFilter',
          default: true,
          methods: {
            apply: () => {
              this.page = 1;
              this.getData();
            }
          },
          component: () => import('@m/transaction/views/TransactionFilter.vue')
        }
      ]
    };
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('transaction', ['getAllTransactions', 'getFilters']),
    transactions() {
      const result = {};
      /** @type {import('@m/transaction/store/state').transactionState['transactions']}*/
      const transactions = this.getAllTransactions;
      const today = new Date();
      const thisMonth = formatDate(today, 'LLLL yyyy');
      transactions.data.forEach((t) => {
        let formatedDate = formatDate(t.txnDate, 'LLLL yyyy');
        formatedDate = formatedDate === thisMonth ? 'This Month' : formatedDate;
        if (!result[formatedDate]) {
          result[formatedDate] = [];
        }
        result[formatedDate].push(t);
      });

      return result;
    },
    disabledLoadMore() {
      return (
        this.loading === true ||
        this.getAllTransactions.total <= this.page * this.limit
      );
    }
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions('transaction', ['fetchAllTransactions']),
    ...mapMutations('transaction', ['updateFilters']),
    goToHome() {
      this.$router.push('/dashboard/home');
    },
    getData(isDownload = false) {
      if (this.getSelectedAccount) {
        const accountId = this.getSelectedAccount.id;
        this.loading = true;

        const filters = {...this.getFilters};
        if(filters.startDate && filters.startDate !== '' && (!filters.endDate || filters.endDate === '')) {
          const today = new Date();
          filters.endDate = formatDate(today, 'yyyy-MM-dd');
        }

        this.fetchAllTransactions({
          accountId,
          limit: this.limit,
          offset: (this.page - 1) * this.limit,
          query: this.search,
          ...filters,
          format: isDownload ? 'pdf' : 'json'
        })
          .then((res) => {
            if (res && isDownload) {
              const url = window.URL.createObjectURL(res);
              const a = document.createElement('a');
              a.href = url;
              a.download = `${this.getSelectedAccount.id}-statement.pdf`;
              a.click();
              a.remove();
            }
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    groupBy(groupName) {
      return this.transactions[groupName] || [];
    },
    load() {
      if (
        this.loading === false &&
        this.getAllTransactions.total > this.page * this.limit
      ) {
        this.loading = true;
        this.page++;
        this.getData();
      }
    },
    filter() {
      // This will not add '/transaction/filter' to the history stack.
      this.showTransactionFilters = true;

    },
    clear(key, value = null) {
      const filters = {
        ...this.getFilters
      };
      filters[key] = value;
      if (key === 'minAmount') {
        filters.maxAmount = value;
      }
      if (key === 'dateRangeValue') {
        filters.startDate = null;
        filters.endDate = null;
      }
      this.updateFilters(filters);
      setTimeout(() => {
        this.getData();
      }, 0);
    },
  },
  destroyed() {
    const filters = {
      contactId: null,
      cardId: null,
      dateRangeValue: null,
      startDate: null,
      endDate: null,
      minAmount: null,
      maxAmount: null,
      txnType: null,
      transferType: null,
      subType: null,
      cardIdFromFilter: null
    };
    this.updateFilters(filters);
  },
  watch: {
    search: debounce(function () {
      this.page = 1;
      this.getData();
    }, 1000),
    /**
     * selected account listener
     */
    getSelectedAccount(newVal, oldVal) {
      if(newVal && oldVal && newVal.id !== oldVal.id) {
        this.search = null;
        this.loading = false,
        this.page = 1;
        this.limit = 20;
        this.getData();
      }
    }
  }
};
</script>

<style lang="scss">
.transactionsList {
  position: relative;
  padding: 105px 60px 0px 32px;

  @media screen and(max-width: 992px) {
    padding-right: 32px;
  }

  @media screen and(max-width: 768px) {
    padding: 105px 16px 0px 16px;
  }

  .widgetContainer__header-fixed {
    padding: 40px 0 0px 0;
  }
  &.full-width .widgetContainer__body {
    padding-top: 15px;
  }

  .sub-header {
    color: var(--branding);
    font-size: 14px;
    padding-bottom: 16px;
    font-weight: 600;
  }

  .header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding-bottom: 6px;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    background-color: #f2f2f7;
    z-index: 1;

    .back {
      cursor: pointer;
      display: flex;
      align-items: center;

      .icon-arrow-left {
        font-size: 18px;
      }

      p {
        color: #3c3c43;
        font-size: 17px;
        padding-left: 6px;
      }
    }

    .export {
      color: var(--branding);
      font-size: 17px;
      cursor: pointer;
    }
  }

  .widgetContainer__body {
    overflow-x: hidden;
    
    &.transactionsList--dashboard-list {
      padding-right: 0;
    }
  }

  &__transactions-title {
    padding-top: 18px;
  }

  &__search {
    display: flex;

    .filter-icon {
      color: var(--branding-primary);
      cursor: pointer;
      background: white;
      height: 64px;
      width: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--radius);
      font-size: 20px;
      margin-left: 10px;
    }
  }

  &__list {
    &.listWithAmountNew {
      ul {
        padding: 0px 16px;
      }
    }
    .card {
      background: white;
      padding: 0 15px;
      border-radius: var(--radius);
      margin-bottom: 30px;
      ul {
        padding: 0px;
      }

      .icon {
        span {
          font-size: 30px;
        }
      }
    }

    ul {
      li {
        .details {
          margin-right: 0;
          flex: 1;
        }
      }
    }
  }

  .searchTags {
    &__tag {
      background-color: var(--branding-primary);
      border-radius: 4px;
      border: 1px solid var(--branding-primary);
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      margin: 0 12px 12px 0;
      text-transform: uppercase;
      padding: 9px;
      display: inline-flex;
      align-items: center;

      span {
        color: #fff;
        font-size: 18px;
        padding-left: 8px;
        cursor: pointer;
      }
    }
  }
  .infinite-loading {
    p {
      padding: 0 0 30px;
    }
  }
}
</style>
